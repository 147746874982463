// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  client_name:"myvoicemyhelp",
  firebase: {
    apiKey: "AIzaSyDhOm488Izk0ebRFslUfYPO9KOsBtTklqs",
    authDomain: "myvoicemyhelp-d28a1.firebaseapp.com",
    projectId: "myvoicemyhelp-d28a1",
    storageBucket: "myvoicemyhelp-d28a1.appspot.com",
    messagingSenderId: "183522618238",
    appId: "1:183522618238:web:96d5cfd1d9bd3e06a7612f",
    measurementId: "G-4GNW67WXRR"
  }
};