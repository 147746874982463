import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../header/header.service';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Util } from '../../../helpers/util';
import {ExcelService} from '../../../services/excel.service';


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  resultFoundGet: any;
  private gridApi;
  private gridColumnApi;

  constructor(private excelService:ExcelService, private httpService: HttpClient, private headerService: HeaderService, private util: Util) { }

  columnDefs = [
    { headerName: 'Full Name', field: 'fullName', cellClass: "cell-wrap-text", width: 130 },
    { headerName: 'Age', field: 'age', cellClass: "cell-wrap-text" },
    { headerName: 'Created Date', field: 'createdDate', cellClass: "cell-wrap-text" }
  ]
  rowData: any;
  ngOnInit() {
    this.headerService.hide();
    this.listDataView();
  }
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.rowData, 'userlist');
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    setTimeout(function () {
      params.api.resetRowHeights();
    }, 500);
  }
  onColumnResized(event) {
    if (event.finished) {
      this.gridApi.resetRowHeights();
    }
  }
  listDataView() {
    this.httpService.get('/api/activeusers').subscribe((res: any) => {
      this.rowData = [];
      res.forEach(element => {
        delete element['isRole'];
        delete element['_id'];
        delete element['password'];
        delete element['mobileNumber'];
        this.rowData.push(element);
        
      });
      this.resultFoundGet = res.length;
    }, (err: HttpErrorResponse) => {
      console.log(err.message);
    });
  }

}
